import React, { useEffect, useRef } from 'react';
import { useTick, Container } from '@pixi/react';
import { Emitter } from '@pixi/particle-emitter'; // Update the import statement
import * as PIXI from 'pixi.js';

const SmokeEffect = ({ x = 0, y = 0, textures, windSpeed }) => {
  const emitterRef = useRef(null);
  const containerRef = useRef(null);

  // [textures.lava, textures.smoke],

  useEffect(() => {
    const emitter = new Emitter(
      containerRef.current,
      {
        "lifetime": {
          "min": 2,
          "max": 15
        },
        "frequency": 0.00002,
        "emitterLifetime": 0,
        "maxParticles": 50,
        "addAtBack": false,
        "pos": {
          "x": 0,
          "y": 0
        },
        "behaviors": [
          {
            "type": "alpha",
            "config": {
              "alpha": {
                "list": [
                  { "value": 1, "time": 0 },
                  { "value": 0.5, "time": 0.5 },
                  { "value": 0, "time": 1 }
                ],
                "isStepped": false
              }
            }
          },
          {
            "type": "moveSpeed",
            "config": {
              "speed": {
                "list": [
                  { "value": 30, "time": 0 }, // Slower starting speed
                  { "value": 10, "time": 1 }  // Slower ending speed
                ],
                "isStepped": false
              },
              "minMult": 0.5
            }
          },
          {
            "type": "scale",
            "config": {
              "scale": {
                "list": [
                  { "value": 0.5, "time": 0 }, // Starts smaller
                  { "value": 1.5, "time": 1 }  // Grows over time
                ],
                "isStepped": false
              },
              "minMult": 1
            }
          },
          {
            "type": "color",
            "config": {
              "color": {
                "list": [
                  { "value": "ff4e00", "time": 0 },     // Bright red lava at start
                  { "value": "ffa500", "time": 0.1 },    // Orange as it cools
                  { "value": "999999", "time": 0.2 },    // Grey transition
                  { "value": "ffffff", "time": 1 }       // White smoke at the end
                ],
                "isStepped": false
              }
            }
          },
          {
            "type": "moveAcceleration",
            "config": {
              "accel": {
                "x": windSpeed * 10,    // Apply wind speed to push particles horizontally
                "y": -2           // Slow upward movement to match the slower particles
              },
              "minStart": 200,
              "maxStart": 300
            }
          },
          {
            "type": "rotation",
            "config": {
              "accel": 0,
              "minSpeed": 20,
              "maxSpeed": 20,
              "minStart": 265,
              "maxStart": 275
            }
          },
          {
            "type": "textureRandom",
            "config": {
              "textures": [
                textures.lava,   // Start as lava texture
                textures.smoke   // Transition to smoke texture
              ]
            }
          },
          {
						"type": "spawnShape",
						"config": {
							"type": "rect",
							"data": {
								"x": -10,
								"y": 10,
								"w": 20,
								"h": 20
							}
						}
					}
        ]
      }
    );

    emitterRef.current = emitter;
    emitter.emit = true;

    return () => {
      // console.log('emitter',emitter);
      emitter.emit = false;  // Stop the emitter
      emitter.cleanup();     // Cleanup any remaining particles
      //emitter.destroy();     // Then destroy
    };
  }, [windSpeed]);

  useTick((delta) => {
    if (emitterRef.current) {
      emitterRef.current.update(delta * 0.05);
    }
  });

  return <Container ref={containerRef} x={x} y={y} />;
};

export default SmokeEffect;
