import { useEffect, useState, useRef } from 'react';
import { Container, Sprite, useTick } from '@pixi/react';

const SunMoon = ({ anchor, hourOfDay, lowY, highY, sprites, textures }) => {
  const elementRef = useRef(null);

  const [sunY, setSunY] = useState(lowY);
  const [moonY, setMoonY] = useState(lowY);
  const [hour, setHour] = useState(hourOfDay);

  const ypos = (t, dir = 1) => {
		// t: current time, b: begInnIng value, c: change In value, d: duration
		dir = dir || 1; // 1 | -1
		if (dir > 0) { // falling - ease in
			// console.log('falling');
			const c = lowY - highY;
			const y = c*t*t*t;
			return y + highY;
		}
		else { // rising - ease out
		 	// console.log('rising');
			const c = lowY - highY;
			const y = c*((t=t-1)*t*t + 1);
			return lowY - y;
		}
	}

	useTick((delta) => {
		if (elementRef.current) {
      // night - moon falling
  		if (hour >= 2 &&  hour < 8 ) {
        //console.log('moon falling');
  			const p = (hour - 2) / 6;
  			setMoonY(ypos(p));
  			setSunY(lowY);
  		}
  		// night - moon rising
  		else if (hour < 2  || hour >= 20) {
        //console.log('moon rising');
  			const h = (hour < 2) ? hour + 4 : hour - 20;
  			const p = h / 6;
  			setMoonY(ypos(p, -1));
  			setSunY(lowY);
  		}
  		// day - sun rising
  		else if ( hour >= 8 && hour < 14 ) {
        //console.log('sun rising');
  			const p = (hour - 8) / 6;
  			setSunY(ypos(p, -1));
  			setMoonY(lowY);
  		}
  		// day - sun falling
  		else {
        //console.log('sun falling');
  			const p = (hour - 14) / 6;
  			setSunY(ypos(p));
  			setMoonY(lowY);
  		}
		}
	});

	useEffect(() => {
    setHour(hourOfDay);
  }, [hourOfDay]);

  return (
    <Container
			ref={elementRef}
  		anchor={anchor}
    >
      <Sprite
        x={sprites.sun.x}
        y={sunY}
        texture={textures.sun}
        anchor={[0.5,0.5]}
      />

      <Sprite
        x={sprites.moon.x}
        y={moonY}
        texture={textures.moon}
        anchor={[0.5,0.5]}
      />

    </Container>
  );
};
export default SunMoon;
