// Common elements
import { Link } from 'react-router-dom';

import Header from "./Header.js";
import Footer from "./Footer.js";

import Section from "../components/Section.js";
import Spinner from "../components/Spinner.js";
import HomePanels from "../components/home/HomePanels.js";
import SubNav from "../components/home/SubNav.js";
import Contact from "../components/contact/Contact";
import ProjectSlider from "../components/projects/ProjectSlider.js";
import ProjectList from "../components/projects/ProjectList.js";

const MobileLayout = ({projects = [], isLoading = false, isLoadingMore = false, category, projectTag, projectTagName = '',handleLoadMore, isContact = false}) => {

	return (
		<>
			{/*<a href="#main-content" className="skiptocontent visually-hidden focusable">Skip to main content</a>*/}
			<Header isMobile={true} />
			<div id="wrap" className="home is-mobile">
				<main role="main" id="main-content">

          { category !== '' || projectTag !== '' ? <>
            <Section name="home-projects">

              { /* <SubNav isHome={false} /> */ }

							{ isContact ?
								<>
									<Contact isMobile={true} />
								</>
							:
								<>
								{ isLoading && <div className="loading-wrap"><Spinner /></div> }

	              {
	                !isLoading && <>
	                  { projectTag && <div className="tag-header">
	                    <div className="tag-header__inner">
	                      <span>{projectTagName !== '' ? projectTagName : projectTag}</span>
	                    </div>
	                  </div> }
	                  { category !== '' && <>
											<div className="breadcrumbs">
												<Link to="/" className="breadcrumb">Home</Link>
												<div className="breadcrumb">Work</div>
											</div>
											<div className="cat-header">
		                    <div className="cat-header__inner">
		                      <h1>{category}</h1>
		                    </div>
		                  </div>
										</> }
	                  <ProjectList
	                    projects={projects}
	                    handleLoadMore = {handleLoadMore}
	                    category={category}
	                    isLoadingMore={isLoadingMore}
	                  />
	                </>
	              }
								</>
							}
            </Section>
            </> : <>
							<section className="ts-section ts-section--home">
								<div className="ts-section__inner">
	                <div className="col-about">
										{/* Panels */}
										<HomePanels showContact={!isContact} isDesktop={false} />
									</div>
								</div>
								<div className="ts-section__bg mobile-bg">
									<div className="mobile-bg__wrap">
										<div className="sky"></div>
										<div className="sun"></div>
										<div className="moon"></div>
										<div className="volcano"></div>
										<div className="grass-1"></div>
										<div className="grass-2"></div>
										<div className="grass-3"></div>
										<div className="grass-4"></div>
										<div className="dude"></div>
										<div className="cliff"></div>
										<div className="grass-5"></div>
										<div className="platform"></div>
										<div className="clouds">
											<div className="cloud"></div>
											<div className="cloud"></div>
											<div className="cloud"></div>
											<div className="cloud"></div>
										</div>
									</div>
								</div>
							</section>

              <Section name="col-projects">
                {
                  projects && projects.length && projects.map((data, i) => {
                    return <ProjectSlider
                      key={i}
                      title={data.title}
                      projects={data.projects}
                      category={data.project_cat}
                      path={"/" + data.project_cat}
                    />
                  })
                 }
              </Section>
            </>
          }


          <Footer isMobile={true} />
				</main>
			</div>
			{/* BG */}
		</>
	)
};

export default MobileLayout;
