import { useContext, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import { ThemeContext } from './context/ThemeContext';
import { FormProvider } from './context/FormContext';

// layouts

import Home from './pages/home/Home';
import Project from './pages/project/Project';

import Error404 from "./pages/Error404";

import ScrollToTop from "./components/ScrollToTop.js";


function App() {

  const { updateScene } = useContext(ThemeContext);

  useEffect(() => {
    // Expose the updateTheme function globally
    window.updateScene = updateScene;
  }, [updateScene]);

  return (
    <FormProvider>
      <Router>
        <ScrollToTop />
        <Routes>

          {/* Base route - Show different layout components based on the device */}
          <Route path="/" element={
            <Home />
          } />

          {/* Specific category routes */}
          <Route path="/featured" element={
            <Home />
          } />
          <Route path="/sites" element={
            <Home />
          } />
          <Route path="/games" element={
            <Home />
          } />
          <Route path="/mobile" element={
            <Home />
          } />
          <Route path="/design" element={
            <Home />
          } />
          <Route path="/ai" element={
            <Home />
          } />

          {/* Project route */}
          <Route exact path="/contact" element={
            <Home isContact={true} />
          } />

          {/* Project route */}
          <Route exact path="/project/:project_slug" element={
            <Project />
          } />

          {/* Tags */}
          <Route exact path="/tag/:tag" element={
            <Home />
          } />

          {/* Catch-all route for 404 */}
    			<Route exact path="*" element={
    				<Error404 />
      		} />
        </Routes>
      </Router>
    </FormProvider>
  );
}

export default App;
