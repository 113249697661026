import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

const ProjectTile = ({project}) => {

  const style = {
    backgroundColor:project.accent_color
  }

  let bgStyle = {};
  if (project.banner_image) {
    bgStyle.background = "url("+project.banner_image+")";
  }

  let bannerStyle = {};
  if (project.padding_bottom) {
    bannerStyle.paddingBottom = project.padding_bottom + '%';
  }

  const content = <>
    <div className="project-tile__bg" style={bgStyle}></div>
    <div className="project-tile__meta" style={style}>
      <div className="project-tile__title">{project.title}</div>
      <div className="project-tile__client">{project.client}</div>
      <div className="project-tile__date">{project.date}</div>
    </div>
  </>;

  return (
    <div className="project-tile">
      {project.is_illustration ? <>
        <div className="project-tile__panel" style={bannerStyle}>
           {content}
        </div>
      </> : <>
        <Link to={'/project/' + project.slug} className="project-tile__panel">
           {content}
        </Link>
      </>}


      <div className="project-tile__tags">
        <div className="project-tags">
          { project.tags && project.tags.map((tag, i) =>{
            return <div key={i} className="project-tag">
              <Link to={"/tag/" + tag.slug}>{parse(tag.name)}</Link>
            </div>
          })}
        </div>
      </div>
    </div>
  );
}
export default ProjectTile;
