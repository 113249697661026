import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useProjectsMethod, useMoreProjectsMethod } from '../../clients/ProjectsClient.js';

import DesktopLayout from '../../layout/LayoutDesktop';
import MobileLayout from '../../layout/LayoutMobile';

const Home = ({isContact = false}) => {

  const location = useLocation();

  // Remove leading and trailing slashes from the pathname
  const trimmedPathname = location.pathname.replace(/^\/|\/$/g, '');

  // Split the pathname to check the structure
  const pathParts = trimmedPathname.split('/');

  // Determine if the path is a category or a tag
  let category = '';
  let projectTag = '';

  if (pathParts[0] === 'tag' && pathParts.length > 1) {
    projectTag = pathParts[1]; // If the path starts with "tag", set projectTag
  }
  else if (pathParts.length === 1) {
    category = pathParts[0]; // If only a single path segment, set as category
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);
  const [projectsData, setProjectsData] = useState(null);
  const [tagName, setTagName] = useState('');

  let term = null;
  let taxonomy = null;
  if (projectTag !== '') {
    term = projectTag;
    taxonomy = 'project_tags';
  }
  else if (category) {
    term = null;
    taxonomy = null;
  }
  const projectsResponse = useProjectsMethod(true, term, taxonomy);


  const [doGetMore, setDoGetMore] = useState(false);
  const [currTerm, setCurrTerm] = useState('');
  const [currTax, setCurrTax] = useState('product_category');
  const [currPage, setCurrPage] = useState(1);
  const moreProjectsResponse = useMoreProjectsMethod(doGetMore,currTerm,currTax,currPage);

  useEffect(() => {
    if (projectsResponse.success) {
      // console.log(projectsResponse.data);

      if (projectTag !== '') {
        setProjectsData(projectsResponse.data.tag_projects);
        setTagName(projectsResponse.data.term_name);
      }
      else {
        setProjectsData(projectsResponse.data.categories);
        setTagName('');
      }

    }
  }, [projectsResponse]);


  useEffect(() => {
    if (moreProjectsResponse.success) {

              console.log(moreProjectsResponse.data);
      setDoGetMore(false);

      let data = null;

      if (currTax == 'project_category') {

        data = [...projectsData];

        // get right list
        const result = data.find(item => item.project_cat === currTerm);

        // add projects to list
        if (result) {
          result.projects = result.projects.concat(moreProjectsResponse.data?.projects ?? []);
          result.page = moreProjectsResponse.data?.page ?? 1;
        }

        // update data
        setProjectsData([...data]);
      }
      else {

        data = {...projectsData};

        data.projects = data.projects.concat(moreProjectsResponse.data?.projects ?? [])
        data.page = moreProjectsResponse.data?.page ?? 1;

        console.log({...data});
        setProjectsData({...data});
      }

    }
    if (moreProjectsResponse.error) {
      setDoGetMore(false);

    }
  }, [moreProjectsResponse]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const handleLoadMore = (category = 'featured', currTag = '', currPage = 1) => {
    // console.log('load more',category, currTag, currPage);

    setCurrTerm(currTag !== '' ? currTag : category);
    setCurrTax(currTag !== '' ? 'project_tags' : 'project_category');
    setCurrPage(currPage + 1);
    setDoGetMore(true);
  }

	return (
		<>
			{isMobile ?
        <MobileLayout
          projects={projectsData}
          isLoading={projectsResponse.loading}
          isLoadingMore={moreProjectsResponse.loading}
          category={category}
          projectTag={projectTag}
          projectTagName={tagName}
          isContact={isContact}
          handleLoadMore={handleLoadMore}
        /> : <DesktopLayout
          projects={projectsData}
          isLoading={projectsResponse.loading}
          isLoadingMore={moreProjectsResponse.loading}
          category={category}
          projectTag={projectTag}
          projectTagName={tagName}
          isContact={isContact}
          handleLoadMore={handleLoadMore}
        />}
		</>
	)

};

export default Home;
