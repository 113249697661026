export const config = {
	WP:{
		BaseUri: process.env.REACT_APP_API_WP
	},
	API:{
		BaseUri: process.env.REACT_APP_API
	},
	CF7:{
		BaseUri: process.env.REACT_APP_CF7_API,
		SiteKey: process.env.REACT_APP_CF7_SITE_KEY,
		FormID: process.env.REACT_APP_CF7_FORMID
	},
};
