import { useState } from "react";
import { useNavigate } from 'react-router-dom';

const ProjectTileSlider = ({project}) => {
  const navigate = useNavigate();
  const [dragging, setDragging] = useState(false);

  const style = {
    backgroundColor:project.accent_color
  }

  let bgStyle = {};

  if (project.banner_image) {
    bgStyle.background = "url("+project.banner_image+")";
  }

  const handleMouseDown = () => {
    setDragging(false);
  };

  const handleMouseUp = () => {
    if (!dragging) {
      navigate('/project/' + project.slug);
    }
  };

  const handleMouseMove = () => {
    setDragging(true);
  };

  const handleClick = (e) => {
    if (dragging) {
      e.preventDefault();
    }
    else {
      navigate('/project/' + project.slug);
    }
  };

  return (
    <div
      className="project-tile project-tile--slider"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
    >
			<div
        className="project-tile__panel"
        onClick={handleClick}
      >
				<div className="project-tile__bg" style={bgStyle}></div>
				<div className="project-tile__meta" style={style}>
					<div className="project-tile__title">{project.title}</div>
					<div className="project-tile__client">{project.client}</div>
					<div className="project-tile__date">{project.date}</div>
				</div>
	    </div>
    </div>
  );
}
export default ProjectTileSlider;
