import React, { useEffect, useState } from 'react';
import { TilingSprite, useTick } from '@pixi/react';
import * as PIXI from 'pixi.js';

const SkyGradient = ({ width, height, x, anchor, timeOfDay, textures }) => {
  // Define alpha states for each background layer
  const [dayAlpha, setDayAlpha] = useState((timeOfDay == 'day'));
  const [sunsetAlpha, setSunsetAlpha] = useState((timeOfDay == 'sunset' || timeOfDay == 'twilight'));
  const [nightAlpha, setNightAlpha] = useState((timeOfDay == 'night'));

  const [hasTransitioned, setHasTransitioned] = useState(false);

  // Define the order of transitions

  // sunset twilight day twilight sunset night

  /*
  const transitions = {
    day: { fadeOut: setDayAlpha, fadeIn: setSunsetAlpha },
    sunset: { fadeOut: setSunsetAlpha, fadeIn: setDayAlpha },
    night: { fadeOut: setNightAlpha, fadeIn: setSunsetAlpha },
    sunrise: { fadeOut: setSunsetAlpha, fadeIn: setNightAlpha },
  };

  // Handle transitions when time of day changes
  useEffect(() => {

    console.log('timeOfDay',timeOfDay);

    // Skip animation on initial render
    if (!hasTransitioned) {
      setHasTransitioned(true);
      return;
    }

    let time = (timeOfDay === 'twilight') ? 'sunset' : timeOfDay;
    const { fadeOut, fadeIn } = transitions[time];

    // animation
    // // Start a new fade-in/out transition
    // let fadeProgress = 0;
    // const interval = setInterval(() => {
    //   fadeProgress += 0.02; // Increase fade progress over time
    //
    //   if (fadeProgress <= 1) {
    //     // Update alpha values based on fade progress
    //     fadeIn(1 - fadeProgress); // Fade out the current scene
    //     fadeOut(fadeProgress); // Fade in the next scene
    //   } else {
    //     clearInterval(interval);
    //   }
    // }, 50); // Run every 50ms for a smooth transition

    // no animation
    fadeIn(0);
    fadeOut(1);


    return () => {
      // clearInterval(interval);
    }
  }, [timeOfDay]);

  */

  useEffect(() => {

    setDayAlpha((timeOfDay == 'day'));
    setSunsetAlpha((timeOfDay == 'sunset' || timeOfDay == 'twilight'));
    setNightAlpha((timeOfDay == 'night'));

  }, [timeOfDay]);

  return (
    <>
      {/* Day Background */}
      <TilingSprite
        texture={textures.day}
        width={width}
        height={height}
				anchor={anchor}
				x={x}
        alpha={dayAlpha}
      />

      {/* Sunset Background */}
      <TilingSprite
        texture={textures.sunset}
        width={width}
        height={height}
				anchor={anchor}
				x={x}
        alpha={sunsetAlpha}
      />

      {/* Night Background */}
      <TilingSprite
        texture={textures.night}
        width={width}
        height={height}
				anchor={anchor}
				x={x}
        alpha={nightAlpha}
      />
    </>
  );
};

export default SkyGradient;
