const Footer = ({isMobile = false}) => {

	const year = new Date().getFullYear();

	return (
		<footer id="footer" className={isMobile ? "main-footer main-footer--mobile" : "main-footer"}>&copy; {year} Taran Stafford. All Rights Reserved.</footer>
	)
};

export default Footer;
