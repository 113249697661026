import { Link } from 'react-router-dom';
import HomePanel from "./HomePanel.js";

const HomePanels = ({showContact = true, isDesktop = true}) => {

	const calcYears = (date) => {
		const difMS = Date.now() - new Date(date);
		const diffDate = new Date(difMS);
		return Math.abs(diffDate.getUTCFullYear() - 1970);
	}

	return (
		<div className="home-panels">

			<HomePanel title="> Hello, World!_" className="about">
				{/*<p>I'm Taran, a full stack developer with over 17 years of industry experience, based in sunny Brighton, UK.</p>

				<p>I design and build websites, games, and apps using a range of modern tools and languages.</p>

				<p>I've been freelancing for 10 years now. Before that I spent 4 years working in-house for Mind Orchard, a Brighton based digital agency.</p>*/}

				{/*<p>I'm Taran, a Brighton-based full-stack developer with over {calcYears("2007-11-02")} years of experience designing and building websites, games, and apps. */}

				<p>I'm Taran, a full stack developer based in sunny Brighton, UK. With over {calcYears("2007-11-02")} years of experience, I design and build websites, games, and apps, using a range of modern tools and languages.</p>

				<p>Currently, I'm the Lead Developer at Gold Pebble, a Hastings-based digital agency renowned for creating engaging, easy-to-use websites and delivering data-driven solutions tailored to complex needs.</p>

				<p>Prior to this, I spent 10 years freelancing and 4 years with Mind Orchard.</p>

				<p>With a strong track record of delivering innovative digital solutions, I'm always excited to explore new challenges and collaborations. Let's connect and discuss how we can bring your next project to life!</p>

			</HomePanel>

			<HomePanel title="Skills" className="skills">
				<h3>Languages</h3>
				<p>PHP, HTML5, CSS3, JavaScript, JQuery, Objective-C, Swift, C++, MySQL</p>

				<h3>Frameworks</h3>
				<p>WordPress, React, Pixi.js, Phaser 3, SpriteKit, Cocos2d-x, WooCommerce, Shopify</p>

				<h3>Software / Tools</h3>
				<p>Photoshop, Illustrator, Xcode, Git</p>
			</HomePanel>

			<HomePanel title="What I can do for&nbsp;you" className="offer">
				<ul className="icon-list">
					<li>
						<Link to="/sites" title="Site dev">
							<div className="icon icon-sites"></div>
							<div className="icon-list-footer">Site Dev</div>
						</Link>
					</li><li>
						<Link to="/games" title="Games">
							<div className="icon icon-games"></div>
							<div className="icon-list-footer">Games</div>
						</Link>
					</li><li>
						<Link to="/mobile" title="Apps">
							<div className="icon icon-apps"></div>
							<div className="icon-list-footer">Apps</div>
						</Link>
					</li><li>
						<Link to="/design" title="Site Design">
							<div className="icon icon-design"></div>
							<div className="icon-list-footer">Site Design</div>
						</Link>
					</li>
				</ul>
			</HomePanel>

			{
				showContact && <HomePanel title="Get in touch!" className="git">
					<p>If you have an idea for a game, want to extend your mobile presence, or just need a quote, feel free to contact me</p>

					<ul>
						<li>
							<Link
								to="/contact"  title="Send me an email">
									<i className="icon-mail"></i>
							</Link>
						</li>
						<li>
							<Link
								to="https://uk.linkedin.com/pub/taran-stafford/17/869/4b0"
								title="I'm on LinkedIn"
								target="_blank">
									<i className="icon-linkedin"></i>
							</Link>
						</li>
						<li>
							<Link
								to="skype:taran.stafford?chat"
								title="Message me on Skype"
							>
								<i className="icon-skype"  target="_blank"></i>
							</Link>
						</li>
					</ul>

				</HomePanel>
			}

			{/* isDesktop && <HomePanel className="unlock">
				<h3>Unlock Recent Work</h3>
			</HomePanel> */}


		</div>
	)
};

export default HomePanels;
