const HomePanel = ({ children, title, className }) => {
  return (
    <div className={"home-panel home-panel--" + className}>
      <div className="home-panel__inner">
        {title && <h2>{ title }</h2>}
        { children }
      </div>
    </div>
  );
}
export default HomePanel;
