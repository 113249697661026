import { useEffect, useState, useRef } from 'react';
import { Sprite } from '@pixi/react';
import { gsap } from 'gsap';

const GrassDude = ({ xPositions, y, height, texture }) => {

  const elementRef = useRef(null);
  const gsapAnimation = useRef(null);

  const [currentX, setCurrentX] = useState(xPositions[0]);
	const [currentY, setCurrentY] = useState(y + height);
  const [yHigh, setYHigh] = useState(y);
  const [yLow, setYLow] = useState(y + height);

  // Randomize delay values
  const randomizeDelays = () => ({
    delay: Math.random() * 6 + 3,
    delay2: Math.random() * 2 + 2,
  });

  const { delay, delay2 } = randomizeDelays();

  // Function to hide the sprite using GSAP animation
  const hide = () => {
    if (elementRef.current) {
      gsapAnimation.current = gsap.to(elementRef.current, {
        duration: 0.6,
        y: yLow,
        ease: "Back.easeIn",
        delay: delay2,
        onUpdate: () => {
          // Update React state whenever GSAP updates the y value
          setCurrentY(elementRef.current.y);
        },
        onComplete: popup,
      });
    }
  };

  // Function to make the sprite pop up using GSAP animation
  const popup = () => {
    if (elementRef.current) {
      // Choose a random X position from xPositions array
      setCurrentX(xPositions[Math.floor(Math.random() * xPositions.length)]);

      gsapAnimation.current = gsap.to(elementRef.current, {
        duration: 0.6,
        y: yHigh,
        ease: "Back.easeOut",
        delay: delay,
        onUpdate: () => {
          // Update React state whenever GSAP updates the y value
          setCurrentY(elementRef.current.y);
        },
        onComplete: hide,
      });
    }
  };

  // Initialize the sprite when the component mounts
  useEffect(() => {

    if (elementRef.current) {

      // Randomly choose whether to start hidden or visible
      if (Math.random() > 0.5) {
        elementRef.current.y = yHigh;
        popup();
      } else {
        elementRef.current.y = yLow;
        hide();
      }
    }

    // Cleanup on component unmount
    return () => {
      gsapAnimation.current.kill(); // Kill the animation if the component unmounts
    };
  }, []);

  return (
    <Sprite
      ref={elementRef}
      texture={texture}
      anchor={[0.5, 1]}
      x={currentX} // Controlled by state
      y={currentY} // Controlled by state
    />
  );
};

export default GrassDude;
