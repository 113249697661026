import React from "react";
import { motion } from "framer-motion";

const ImageStack = ({ images }) => {
  return (
    <div className="image-stack">
      {images.map((img, i) => {
        const style = {
          paddingBottom : img.paddingBottom + '%'
        }
        return <motion.div
          key={i}
          className="image-stack__slide"
          initial={{ opacity: 0, y: 50 }} // Initial state: hidden and moved down
          whileInView={{ opacity: 1, y: 0 }} // Animate to: visible and at normal position
          transition={{ duration: 0.8, ease: "easeOut" }} // Animation timing
          viewport={{ once: true, amount: 0.2 }} // Trigger animation when 20% of the image is in view
          style={style}
        >
					<img
	          src={img.src}
	          alt={img.alt}
						className="image-stack__image"
					/>
				</motion.div>
      })}
    </div>
  );
};

export default ImageStack;
