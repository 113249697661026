
const Section = ({ name, children, after = null }) => {

	const cName = name && name != '' ? "ts-section ts-section--"+name : "ts-section"

	return <section className={cName}>
		<div className="ts-section__inner">
			{ children }
		</div>
		{ after }
	</section>
}
export default Section;
