import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

const SubNav = ({isHome = true}) => {
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <nav className="sub-nav">
			<ul>
				{ isHome ?
          <li className={isActive('/') ? 'active' : ''}><Link to="/">Featured</Link></li>
        :
          <>
            <li className={isActive('/') ? 'active' : ''}><Link to="/">Home</Link></li>
            <li className={isActive('/featured') ? 'active' : ''}><Link to="/featured">Featured</Link></li>
          </>
        }
				<li className={isActive('/sites') ? 'active' : ''}><Link to="/sites">Sites</Link></li>
				<li className={isActive('/games') ? 'active' : ''}><Link to="/games">Games</Link></li>
				<li className={isActive('/mobile') ? 'active' : ''}><Link to="/mobile">Mobile</Link></li>
				<li className={isActive('/design') ? 'active' : ''}><Link to="/design">Design</Link></li>
				<li className={isActive('/ai') ? 'active' : ''}><Link to="/ai">AI</Link></li>
			</ul>
    </nav>
  );
}
export default SubNav;
