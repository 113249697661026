import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import parse from 'html-react-parser';

import Header from "../../layout/Header.js";
import Footer from "../../layout/Footer.js";

import Section from "../../components/Section.js";
import ImageStack from "../../components/ImageStack.js";
import Spinner from '../../components/Spinner.js';
import SubNav from "../../components/home/SubNav.js";

import { useProjectMethod } from '../../clients/ProjectsClient.js';

const Project = ( ) => {

	const { project_slug } = useParams();

	const projectResponse = useProjectMethod(true, project_slug);
  const [galleryImages, setGalleryImages] = useState([]);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 960);

  const [btnHovered, setBtnHovered] = useState(false);

  useEffect(() => {
    if (projectResponse.success) {

			// console.log(projectResponse.data);

			if (projectResponse.data?.gallery) {

				const gallery = projectResponse.data?.gallery.map((image) => ({
				  title: image.title, // The title of the image
				  src: image.sizes['large'], // The main URL
				  alt: image.alt, // The main URL
				  paddingBottom: image.height / image.width * 100, // The main URL
				  // projectGallery: image.sizes['project-gallery'],
				  // projectTile: image.sizes['project-tile'],
				  // thumbnail: image.sizes['thumbnail'], //
				}));
	      setGalleryImages(gallery);

			}
    }
  }, [projectResponse]);

	const accentStyle = projectResponse.success ? { color:projectResponse.data.accent_color } : {};
	const bgStyle = projectResponse.success ? { backgroundColor:projectResponse.data.bg_color} : {};

	const btnStyleBase = projectResponse.success ? { backgroundColor:projectResponse.data.accent_color, borderColor:projectResponse.data.accent_color } : {};

	const btnStyleHover = projectResponse.success ? { backgroundColor:'#fff', borderColor:projectResponse.data.accent_color, color:projectResponse.data.accent_color} : {};

	const  btnStyle = btnHovered
        ? { ...btnStyleBase, ...btnStyleHover }
        : btnStyleBase;

	const project = projectResponse.success ? projectResponse.data : null;

	let iframe = null;
	if (project?.iframe) {
		// console.log(project.iframe);
		const pad = project.iframe.padding ?? 0;

		iframe = <div class="iframe-wrapper" style={
			{paddingBottom: pad + '%'}
		}>
			<iframe src={project.iframe.src} frameborder="0" scrolling="no"></iframe>
		</div>
	}

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

	return (
		<>
			{/*<a href="#main-content" className="skiptocontent visually-hidden focusable">Skip to main content</a>*/}
			<Header isMobile={isMobile} />
			<div id="wrap" className={isMobile ? "project is-mobile" : "project"}>

				<main role="main" id="main-content" style={bgStyle}>
					<article>
						<Section name="single-project">

							{ !isMobile && <SubNav /> }

								 <div className="flex">
		 							<div className="col-main">
										{ projectResponse.loading && <Spinner /> }
			 						 	{ projectResponse.success && <>
											{ iframe }
											<ImageStack images={galleryImages} />
			 							 </> }
		 							</div>
		 							<div className="col-side">
										<div className="project-detail">

										{ projectResponse.success && <>

											<div className="project-detail__thumbnail">
												<div className="image-wrap">
													{project.project_thumb && <img
														src={project.project_thumb}
													/>}
												</div>
											</div>
											<div className="project-detail__nav">
												{ project.prevProject ? <Link to={"/project/" + project.prevProject}><i className="icon-angle-left"></i> PREVIOUS</Link> : '' }

												{ project.nextProject ? <Link to={"/project/" + project.nextProject}>NEXT <i className="icon-angle-right"></i></Link> : '' }
											</div>
											<hr />
											<h1 className="project-detail__name" style={accentStyle}>
												{project.title}
											</h1>
											<div className="project-detail__client">
												{project.client}
											</div>
											<div className="project-detail__date">
												{project.date}
											</div>
											{project.website_url !== '' && <div className="project-detail__link">
												<a
													href={project.website_url}
													className="btn btn-view"
													target="_blank"
													style={btnStyle}
							            onMouseEnter={() => setBtnHovered(true)}
							            onMouseLeave={() => setBtnHovered(false)}
												>{project.link_text ?? 'View'}</a>
											</div>}
											{ project.post_content !== '' && <>
												<hr />
												<div className="project-detail__description">
													{parse(project.post_content)}
												</div>
											</>}
											<hr />
											<div className="project-detail__tags">
												<ul className="project-tags">
													{ project.tags && project.tags.map((tag, i) =>{
														return <li key={i} className="project-tag">
															<Link to={"/tag/" + tag.slug}>{tag.name}</Link>
														</li>
													})}
												</ul>
											</div>

										</> }

										</div>
		 							</div>
		 						</div>

						</Section>
					</article>
          <Footer />

				</main>
			</div>
			{/* BG */}
		</>
	)

};

export default Project;
