import { useState, useRef } from "react";
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ProjectTileSlider from "./ProjectTileSlider.js";

const ProjectSlider = ({ title, projects = [], category = '', path = '/' }) => {


  const sliderRef = useRef(null);

  // Split the projects into two arrays: first 4 and the rest
  const initialProjects = projects ? projects.slice(0, 4) : [];
  const remainingProjects = projects ? projects.slice(4) : [];
  const [currentSlide, setCurrentSlide] = useState(0);

  // Slick slider settings
  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    slidesToShow: 1.2,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      const roundedNext = Math.ceil(next);
      setCurrentSlide(roundedNext);
    },
    appendDots: (dots) => (
      <ul>
        {dots.map((dot, index) => (
          <li key={index} className={index === Math.ceil(currentSlide) ? "slick-active" : ""}>
            {dot}
          </li>
        ))}
      </ul>
    ),
    // responsive: [
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1.2,
    //       slidesToScroll: 1,
    //     }
    //   }
    // ]
  };

  if (initialProjects.length === 0) {
    return <></>
  }

  return (
    <div className={"project-slider project-slider--" + category}>
      <h2>{title}</h2>

      {/* Display the first 4 projects */}
      <Slider ref={sliderRef} {...settings}>
        {initialProjects && initialProjects.length && initialProjects.map((project, i) => (
          <ProjectTileSlider key={i} project={project} />
        ))}
        { remainingProjects.length > 0 && (
          <Link to={path}>
            <div className="view-more">
              <div className="view-more__inner">
                <span>View more</span>
              </div>
            </div>
          </Link>
        ) }
      </Slider>
      <Link to={path} className="view-all">
        <span>View All</span>
      </Link>
    </div>
  );
};

export default ProjectSlider;
