import { useEffect, useState, useRef } from 'react';
import { Container, Sprite, useTick } from '@pixi/react';

const Clouds = ({ children, timeOfDay, windSpeed = 1, windowWidth, textures, x, minY = 50, maxY = 400, numClouds = 5 }) => {

  const [clouds, setClouds] = useState([]); // Store cloud properties
  const [wSpeed, setWSpeed] = useState(windSpeed);

  const containerRef = useRef(null);

  // Cloud states for different times of day
  const cloudTextures = {
    day: textures.day,
    sunrise: textures.sunset,
    sunset: textures.sunset,
    twilight: textures.twilight,
    night: textures.night,
  };

  // Initialise clouds when component mounts
  useEffect(() => {
    const initialClouds = [];

    for (let i = 0; i < numClouds; i++) {
      initialClouds.push(createNewCloud(windowWidth, minY, maxY, cloudTextures[timeOfDay]));
    }

    setClouds(initialClouds);
  }, []);


  useEffect(() => {
    setWSpeed(windSpeed);
  }, [windSpeed]);


  // Create a new cloud object with randomized properties
  const createNewCloud = (windowWidth, minY, maxY, texture) => {
    return {
      x: Math.floor(Math.random() * windowWidth), // Initial X position
      y: Math.floor(Math.random() * (maxY - minY)) + minY, // Randomized Y position
      scale: Math.random() * 0.4 + 0.2, // Random scale between 0.2 and 0.6
      speed: Math.random() + 1, // Random speed between 1 and 2
      texture, // Initial texture based on time of day
    };
  };

  // Update cloud positions and reset when they go off-screen
  useTick((delta) => {
    setClouds((prevClouds) =>
      prevClouds.map((cloud) => {
        if (wSpeed === 0) {
          return { ...cloud };
        }
        let newX = cloud.x + cloud.speed * delta * wSpeed;

        // If cloud is off-screen, reset position
        if (newX > windowWidth + 250 ) {
          return {
            ...cloud,
            x: -250, // Reset to the left side
            y: Math.floor(Math.random() * (maxY - minY)) + minY, // Randomize new Y position
            scale: Math.random() * 0.4 + 0.2, // Randomize scale
            speed: Math.random() * 1 + 1, // Randomize speed
            texture: cloudTextures[timeOfDay], // Update texture based on time of day
          };
        }

        if (newX < -250) {
          return {
            ...cloud,
            x: windowWidth + 250, // Reset to the left side
            y: Math.floor(Math.random() * (maxY - minY)) + minY, // Randomize new Y position
            scale: Math.random() * 0.4 + 0.2, // Randomize scale
            speed: Math.random() * 1 + 1, // Randomize speed
            texture: cloudTextures[timeOfDay], // Update texture based on time of day
          };
        }

        return { ...cloud, x: newX }; // Update X position
      })
    );
  });

  // Re-initialize cloud textures when time of day changes
  useEffect(() => {
    setClouds((prevClouds) =>
      prevClouds.map((cloud) => ({
        ...cloud,
        texture: cloudTextures[timeOfDay],
      }))
    );
  }, [timeOfDay]);

  return (
    <Container
      ref={containerRef}
      x={x}
      >
      {/* Render all cloud sprites */}
      {clouds.map((cloud, index) => (
        <Sprite
          key={index}
          texture={cloud.texture}
          x={cloud.x}
          y={cloud.y}
          scale={cloud.scale}
          anchor={[0.5, 0.5]}
        />
      ))}
    </Container>
  );
};
export default Clouds;
