import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import LinesButton from "../components/LinesButton";

const Header = ({isMobile = false}) => {
  const [_menuOut, setMenuOut] = useState(false);
  const [_submenuOpen, setSubmenuOpen] = useState(false);

  const location = useLocation();

  // Toggle mobile menu
  const onToggleMobileMenu = () => {
    setMenuOut((prevState) => {
      const newState = !prevState;
      if (newState) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
      return newState;
    });
  }

  const onToggleSubmenu = () => {
    setSubmenuOpen(!_submenuOpen);
  }

  const onItemSelected = () => {
    setMenuOut(false);
    document.body.classList.remove('no-scroll');
  }

  const isActive = (path) => location.pathname === path;

  // Cleanup class on unmount
  useEffect(() => {
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

	const intro = <Link to="/" className="intro">
		<div className="intro__name">Taran Stafford</div>
		<div className="intro__jobdesc">Full-stack Developer</div>
		<div className="intro__languages">
			PHP&nbsp;&nbsp;React&nbsp;&nbsp;HTML5&nbsp;&nbsp;CSS3&nbsp;&nbsp;JS
		</div>
	</Link>;

	return (
    <>
  		<header className={isMobile ? "main-header main-header--mobile" : "main-header"} id="main-header">
  			<div className="main-header__inner">

  				{isMobile ? <div className="flex">
  					<div className="col">
  						{intro}
  					</div>
  					<div className="col text-right">
  						<LinesButton
                active={_menuOut}
                onClick={onToggleMobileMenu}
  						/>
  					</div>
  				</div> : intro }

  			</div>
  		</header>
      {isMobile && (
        <div className={`slide-nav ${_menuOut ? 'menu-out' : ''}`}>
        <div className="slide-nav__inner">
          <ul>
            <li className={isActive('/') ? 'active' : ''}>
              <Link to="/" onClick={onItemSelected}>Home</Link>
            </li>
            <li>
              <button className={`submenu-toggle ${_submenuOpen ? 'submenu-out' : ''}`} onClick={onToggleSubmenu}>
                Work <div className="icon-down-open"></div>
              </button>
              <ul className={`submenu ${_submenuOpen ? 'open' : ''}`}>
                <li className={isActive('/featured') ? 'active' : ''}>
                  <Link to="/featured" onClick={onItemSelected}>Featured</Link>
                </li>
                <li className={isActive('/sites') ? 'active' : ''}>
                  <Link to="/sites" onClick={onItemSelected}>Sites</Link>
                </li>
                <li className={isActive('/games') ? 'active' : ''}>
                  <Link to="/games" onClick={onItemSelected}>Games</Link>
                </li>
                <li className={isActive('/mobile') ? 'active' : ''}>
                  <Link to="/mobile" onClick={onItemSelected}>Mobile</Link>
                </li>
                <li className={isActive('/ai') ? 'active' : ''}>
                  <Link to="/ai" onClick={onItemSelected}>AI</Link>
                </li>
              </ul>
            </li>
            <li className={isActive('/contact') ? 'active' : ''}>
              <Link to="/contact" onClick={onItemSelected}>Contact Me</Link>
            </li>
          </ul>
        </div>
      </div>
    )}
    </>
	)
};

export default Header;
