import React, { createContext, useEffect, useState } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {

  const [windSpeed, setWindSpeed] = useState(0.5); // negative for westerly
  const [isRaining, setIsRaining] = useState(false);
  const [hour, setHour] = useState(null);

  const updateScene = (args) => {
    if ('windSpeed' in args) {
      setWindSpeed(args.windSpeed);
    }
    if ('isRaining' in args) {
      setIsRaining(args.isRaining);
    }
    if ('hour' in args) {
      setHour(args.hour);
    }
  };

  useEffect(() => {
    console.log(windSpeed, isRaining, hour);
  }, [windSpeed, isRaining, hour]);

  return (
    <ThemeContext.Provider value={{ windSpeed, isRaining, hour, updateScene }}>
      {children}
    </ThemeContext.Provider>
  );
};
