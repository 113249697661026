import React, { useEffect, useRef } from 'react';
import { Container, Sprite } from '@pixi/react';

const Trees = ({ anchor, trees, textures }) => {
  const containerRef = useRef(null); // Reference for the main container

  // Define the texture mappings for tree types (pine, oak, etc.)
  const treeTextures = {
    pine: {
      top: textures.pine.top,
      trunk: textures.pine.trunk,
    },
    oak: {
      top: textures.oak.top,
      trunk: textures.oak.trunk,
    },
    // Add other tree types as needed...
  };

  // Utility function to generate a single tree
  const renderTree = (treeData, index) => {
    const { pos, scale, tree } = treeData;
    const treeType = treeTextures[tree];
    if (!treeType) return null;

    return (
      <Container
        key={index}
				scale={scale}
      >
        {/* Trunk Sprite */}
        <Sprite
          texture={treeType.trunk}
          x={pos.x + trees.scene.offsetX}
          y={pos.y}
          anchor={[0.5, 0]}
        />

        {/* Top Sprite */}
        <Sprite
          texture={treeType.top}
          x={pos.x + trees.scene.offsetX}
          y={pos.y - textures[tree].trunk.height - 40}
          anchor={[0.5, 0]}
        />
      </Container>
    );
  };


  return (
    <Container
      ref={containerRef}
      anchor={anchor}
      y={trees.scene.offsetY}
    >
      {/* Render all trees dynamically */}
      {trees.positions.map((treeData, index) => renderTree(treeData, index))}
    </Container>
  );
};

export default Trees;
