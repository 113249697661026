// Common elements
import Header from "./Header";
import Footer from "./Footer";

import Section from "../components/Section";
import Spinner from "../components/Spinner";
import HomePanels from "../components/home/HomePanels";
import SubNav from "../components/home/SubNav";
import Contact from "../components/contact/Contact";
import ProjectList from "../components/projects/ProjectList";

import AnimatedBG from "../components/pixi/AnimatedBG";

const DesktopLayout = ({projects = null, isLoading = false, isLoadingMore = false, category, projectTag, projectTagName = '',handleLoadMore, isContact = false}) => {

  const currCategory = category !== '' ? category : 'featured';

	return (
		<>
			{/*<a href="#main-content" className="skiptocontent visually-hidden focusable">Skip to main content</a>*/}
			<Header />
			<div id="wrap" className="home">
				<main role="main" id="main-content">

					<Section name="home">
						{/* Nav */}
						<SubNav />

						<div className="flex">
							<div className="col-about">
								{/* Panels */}
								<HomePanels showContact={!isContact} />
							</div>
							<div className="col-projects">
                { isContact ?
                  <>
                  <Contact />
                  </>
                :
                  <>
                    { isLoading && <div className="loading-wrap"><Spinner /></div> }
                    {
                      !isLoading && <>
                        { projectTag && <div className="tag-header">
                          <div className="tag-header__inner">
                            <span>{projectTagName !== '' ? projectTagName : projectTag}</span>
                          </div>
                        </div> }
                        <ProjectList
                          projects={projects}
                          handleLoadMore={handleLoadMore}
                          category={currCategory}
                          isLoadingMore={isLoadingMore}
                        />
                      </>
                    }
                  </>
                }
							</div>
						</div>

					</Section>

          <Footer />

				</main>
			</div>
			{/* BG */}
      <div className="wrap-bg" id="wrap-bg"></div>
      <div className="parallax-bg">
        <AnimatedBG id="animated-bg" />
      </div>
		</>
	)
};
export default DesktopLayout;
