import { useContext, useEffect, useState } from 'react';
import { Stage, Container, Sprite, TilingSprite, useTick } from '@pixi/react';
import { addStats, Stats } from 'pixi-stats';
import * as PIXI from 'pixi.js';

import { ThemeContext } from '../../context/ThemeContext';
import gsap from 'gsap';  // Import GSAP for animation

import spritesheetJSON from '../../images/animated-bg/bg_assets.json';
import spritesheetPNG from '../../images/animated-bg/bg_assets.png';
import sceneData from './scene.json';

import MainTick from './MainTick';
import TickComponent from './TickComponent';
import SkyGradient from './SkyGradient';
import SunMoon from './SunMoon';
import Clouds from './Clouds';
import Trees from './Trees';
import Platforms from './Platforms';
import GrassDude from './GrassDude';
import SmokeEffect from './SmokeEffect';

const AnimatedBG = () => {

  const [_windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [_windowHeight, setWindowHeight] = useState(window.innerHeight);

	const [_scene, setScene] = useState(sceneData);

  const [_scrollY, setScrollY] = useState(0);
  const [_textures, setTextures] = useState(null);
  const [_timeOfDay, setTimeOfDay] = useState(null);
  const [_timeOfDayOverride, setTimeOfDayOverride] = useState(null);
  const [_hourOfDay, setHourOfDay] = useState(null);

  const [_isAnimating, setIsAnimating] = useState(false);

  // global vars - for animation
  const { hour: contextHour } = useContext(ThemeContext);
  const { windSpeed } = useContext(ThemeContext);

  const [_colorFilter, setColorFilter] = useState(new PIXI.filters.ColorMatrixFilter());

	const scrollSpeed = 0.2;


	const setTimeVars = (hour) => {
    let timeDay = 'day';
    const filter = new PIXI.filters.ColorMatrixFilter();

		if (hour >= 8 && hour < 9 || hour >= 19 && hour < 20.5) {
			// sunset
			timeDay = 'sunset';
		}
		else if (hour >= 9 && hour < 10 || hour >= 18 && hour < 19) {
			// twilight
			timeDay = 'twilight';
		}
		else if (hour >= 5.5 && hour < 8 || hour >= 20.5 && hour < 22.5) {
			// moon rise
			timeDay = 'night';
		}
		else if (hour < 5.5 || hour >= 22.5) {
			// night
			timeDay = 'night';
		}

    if (hour < 9 || hour > 19.5) {

			if (hour >= 7.5 && hour < 9) {
        // filter.tint(0xffcc99);
        // sunrise
        filter.brightness(0.8);
			}
      else if (hour >= 19.5 && hour < 21.5) {
        // sunset
				filter.brightness(0.5);
			}
			else {
        // night
				filter.brightness(0.7);
			}
		}
    else {
			filter.reset();
		}

    setColorFilter(filter);
		setTimeOfDay(timeDay);
  }

  const calculateCurrentHour = () => {
    const date = new Date();
    const mins = date.getMinutes() / 60;
    let hour = date.getHours() + mins;
    return hour;
  }

	const updateTime = () => {
    if (_isAnimating) {
      return;
    }

    if (contextHour) {
      if (contextHour !== _hourOfDay) {
        // Animate the transition between times of day using GSAP
        setIsAnimating(true);
        const hourObj = { hour: _hourOfDay };

        gsap.to(hourObj, {
          duration: 6,  // Duration of transition
          hour: contextHour,
          ease: 'power1.inOut',
          onUpdate: () => {
        		setHourOfDay(hourObj.hour);
            setTimeVars(hourObj.hour);
          },
          onComplete: () => {
            setHourOfDay(contextHour);
            setTimeVars(hourObj.hour);
            setIsAnimating(false);
          }
        });
      }
    }
    else {
      const hour = calculateCurrentHour();
  		setHourOfDay(hour);
      setTimeVars(hour);
    }

	}

  // init
	useEffect(() => {

		updateTime();

    // Create a new base texture using the imported PNG
    const baseTexture = PIXI.BaseTexture.from(spritesheetPNG);

    // Create a spritesheet instance with the imported JSON and base texture
    const sheet = new PIXI.Spritesheet(baseTexture, spritesheetJSON);

    // Parse the spritesheet data
    sheet.parse(() => {

    });
		setTextures(sheet.textures);

    // Handle window resizing
    const onResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', onResize);
		onResize();

    // // Handle window scroll
    // const onScroll = () => {
    //   setScrollY(document.documentElement.scrollTop);
    // };
    // window.addEventListener('scroll', onScroll);

    return () => {
      // Clean up event listeners and destroy the spritesheet
      window.removeEventListener('resize', onResize);
      //window.removeEventListener('scroll', onScroll);
      sheet.destroy(true);
    };
  }, []);

  useEffect(() => {
    const wrapDiv = document.getElementById('wrap-bg');

    if (wrapDiv) {
      wrapDiv.className = `wrap-bg wrap-bg--${_timeOfDay}`;
    }
  }, [_timeOfDay]);

  // pixi stats - for testing
  // FPS Frames rendered in the last second. The higher the number the better.
  // MS Milliseconds needed to render a frame. The lower the number the better.
  // MB MBytes of allocated memory. (Run Chrome with --enable-precise-memory-info)
  // DC Draw Calls made within one frame.
  // TC Texture Count used within one frame.
  // CUSTOM User-defined panel support.

  // useEffect(() => {
  //   // Initialize pixi-stats
  //   const stats = new Stats();
  //   document.body.appendChild(stats.domElement);
  //
  //   const ticker = PIXI.Ticker.shared;
  //
  //   // Sync pixi-stats with the shared ticker
  //   ticker.add(() => {
  //     stats.update();  // Update stats for each frame
  //   });
  //
  //   // Clean up when the component unmounts
  //   return () => {
  //     document.body.removeChild(stats.domElement);
  //     ticker.remove(stats.update);
  //   };
  // }, []);

	// useEffect(() => {
  //   setContextHour(contextHour);
  // }, [contextHour]);

	// main loop - updating global vars
	const sceneTick = (delta, el) => {
		// console.log('sceneTick');
    updateTime();
		setScrollY(document.documentElement.scrollTop);
	}

	// Recursive function to render components based on JSON configuration
  const renderScene = (node) => {
    if (!node) return null;

    const { type, id, texture, anchor, children, tick, component, ...props } = node;

		// Handle ticking for elements
    let tickFunction = null;
    if (tick && tick.func) {
      switch (tick.func) {
        case 'parallax':
          tickFunction = (delta,el,node, speed) => {
						el.y = node.y - Math.round(_scrollY * scrollSpeed * speed);
          };
          break;
        default:
          break;
      }
    }

    // Handle dynamic rendering based on type
    switch (type) {
      case 'Container':
        return (
          <TickComponent
						key={id}
						anchor={anchor}
						x={_windowWidth * 0.5}
						node={node}
						func={tickFunction}
						scrollSpeed={tick.scrollSpeed ?? 1}
            colorFilter={_colorFilter}
            doFilter={id !== 'sky'}
					>
            {children && children.map((child) => renderScene(child))}
          </TickComponent>
        );
      case 'Sprite':
        return (
          <Sprite
            key={id}
            texture={_textures[texture[0]]}
            anchor={anchor}
            {...props}
          />
        );
      case 'TilingSprite':
        return (
          <TilingSprite
            key={id}
            texture={_textures[texture[0]]}
            anchor={[0.5, 0]}
            uvRespectAnchor={true} // keep tiles centered
	          width={_windowWidth}
            {...props}
          />
        );
      case 'SkyGradient':
				return (
					<SkyGradient
						key={id}
						width={_windowWidth}
						timeOfDay={_timeOfDay}
						x={0}
            anchor={anchor}
						textures={{
							day:_textures[node.states.day],
							sunset:_textures[node.states.sunset],
							night:_textures[node.states.night]
						}}
						{...props}
					/>
				);
      case 'SunMoon':
        return (
					<SunMoon
						key={id}
						hourOfDay={_hourOfDay}
            anchor={anchor}
						textures={{
							sun:_textures[node.sprites.sun.texture],
							moon:_textures[node.sprites.moon.texture],
						}}
						{...props}
						>
					</SunMoon>
				)
      case 'Clouds':
        return (
					<Clouds
						key={id}
						timeOfDay={_timeOfDay}
						windowWidth={_windowWidth}
						x={_windowWidth * -0.5}
            anchor={anchor}
            windSpeed={windSpeed}
						textures={{
							day:_textures[node.states.day],
							night:_textures[node.states.night],
							twilight:_textures[node.states.sunset],
							sunset:_textures[node.states.sunset],
						}}
						{...props}
						>
					</Clouds>
				)
      case 'GrassDude':
        return (
					<GrassDude
						key={id}
						texture={_textures[node.texture[0]]}
						{...props}
						>
					</GrassDude>
				)
      case 'Platforms':
        return (
					<Platforms
						key={id}
            anchor={anchor}
						x={_windowWidth * -0.5}
						texture={_textures[node.texture]}
						{...props}
						>
					</Platforms>
				)
      case 'Trees':
        return (
					<Trees
						key={id}
	          width={_windowWidth}
            anchor={anchor}
						x={_windowWidth * 0.5}
						textures={{
              pine: {
                top: _textures[node.trees.tree.pine.top],
                trunk: _textures[node.trees.tree.pine.trunk],
              },
              oak: {
                top: _textures[node.trees.tree.oak.top],
                trunk: _textures[node.trees.tree.oak.trunk],
              },
            }}
						{...props}
						>
					</Trees>
				)
      case 'SmokeEffect':
        return (
					<SmokeEffect
						key={id}
            windSpeed={windSpeed}
						{...props}
						textures={{
							lava:_textures[node.textures.lava],
							smoke:_textures[node.textures.smoke],
						}}
					></SmokeEffect>)
      case 'CustomComponent':
        // Render custom components like Platforms or Trees
        if (component === 'Trees') return <Trees key={id} />;
        return null;
      default:
        return null;
    }
  };

  return (
    <Stage
      width={_windowWidth}
      height={_windowHeight}
      options={{
				antialias: true,
				transparent: true,
				backgroundAlpha: 0,
			}}
		>
			<MainTick func={sceneTick} >
				{ _scene && _textures && _scene.scene.map((element) => renderScene(element)) }
    	</MainTick>
    </Stage>
  );
};

export default AnimatedBG;
