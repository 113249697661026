import { useEffect, useRef } from 'react';
import {  Container, useTick } from '@pixi/react';

const TickComponent = ({ node, children, x=0, anchor=null, func = null, scrollSpeed = 1, colorFilter, doFilter = false }) => {
  const elementRef = useRef(null);

	useTick((delta) => {
		if (elementRef.current) {
			if (func) {
				func(delta, elementRef.current, node, scrollSpeed);
			}
		}
	});

  useEffect(() => {
    if (colorFilter && elementRef.current && doFilter) {
      elementRef.current.filters = [colorFilter];
    }
  }, [colorFilter]);

	const { ...props } = node;

  return (
    <Container
			ref={elementRef}
			x={x}
			anchor={anchor}
			{...props}>
      {children}
    </Container>
  );
};
export default TickComponent;
