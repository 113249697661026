import { useState, useEffect, useContext } from "react";
import { Link } from 'react-router-dom';
import { useSubmitContactForm } from '../../clients/ContactForm7Client';
import { config } from '../../Config';

import { FormContext } from '../../context/FormContext';

const Contact = ({isMobile = false}) => {

  const { formData, setFormData } = useContext(FormContext);

  const [sendRequest, setSendRequest] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const [responseMessageStatus, setResponseMessageStatus] = useState('');

  const createFormData = (data) => {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });

    formData.append('_wpcf7', config.CF7.FormID);
    formData.append('_wpcf7_version', '6.0.1');
    formData.append('_wpcf7_locale', 'en_GB');
    formData.append('_wpcf7_unit_tag', `wpcf7-f${config.CF7.FormID}-o1`);
    //formData.append('_wpcf7_container_post', '0');

    return formData;
  };

  const contactSubmitResponse = useSubmitContactForm(
    sendRequest,
    config.CF7.FormID, // contact form 7 ID
    createFormData(formData)
  );

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = await window.grecaptcha.execute(config.CF7.SiteKey, { action: 'submit' });
      setFormData((prev) => ({
        ...prev,
        '_wpcf7_recaptcha_response': token 
      }));
      setSendRequest(true);
    }
    catch (error) {
      console.error("reCAPTCHA failed:", error);
      setResponseMessage("Failed to verify reCAPTCHA. Please try again.");
      setResponseMessageStatus("error");
    }

    // // Fetch reCAPTCHA token
    // const token = await window.grecaptcha.execute(config.CF7.SiteKey, {action: 'submit'});
    // setFormData((prev) => ({ ...prev, 'g-recaptcha-response': token }));
    //
    // // Trigger the form submission
    // setSendRequest(true);
  };

  const obfuscateEmail = () => {
    const user = "taran";
    const domain = "taranstafford.co.uk";
    return `${user}@${domain}`;
  };

  const onMailTo = () => {
    const email = obfuscateEmail();
    const subject = encodeURIComponent("Hello!");
    const body = encodeURIComponent("I wanted to get in touch with you.");

    // Create the mailto link dynamically
    const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;

    // Open the mail client
    window.location.href = mailtoLink;
  };

  useEffect(() => {
    if (contactSubmitResponse.success) {
      console.log(contactSubmitResponse);
      setResponseMessage(contactSubmitResponse.data.message);
      setResponseMessageStatus(contactSubmitResponse.data.status);
      setSendRequest(false);
    }
    if (contactSubmitResponse.error) {
      console.log(contactSubmitResponse);
      setResponseMessage(contactSubmitResponse.data.message);
      setResponseMessageStatus(contactSubmitResponse.data.status);
      setSendRequest(false);
    }
  }, [contactSubmitResponse]);

  return (
    <>
    { isMobile && <div className="breadcrumbs">
      <Link to="/" className="breadcrumb">Home</Link>
      <div className="breadcrumb">Contact</div>
    </div> }
    <div className={"contact"}>
      <div className="contact__inner">
        <h2>Get in touch!</h2>
        <p>If you have an idea for a game, want to extend your mobile presence, or just need a quote, feel free to contact me</p>

        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="flex">
              <div className="col-6">
                <label className="required">Name</label>
                <input
                  type="text"
                  name="your-name"
                  value={formData['your-name']}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-6">
                <label className="required">Email</label>
                <input
                  type="email"
                  name="your-email"
                  value={formData['your-email']}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="form-row">
            <label className="required">Message</label>
            <textarea
              name="your-message"
              value={formData['your-message']}
              onChange={handleChange}
              rows={10}
              required
            ></textarea>
          </div>
          <div className="btn-wrap">
            <button type="submit" className={contactSubmitResponse.loading === true ? "btn btn-solid btn-git-send is-spinning" : "btn btn-solid btn-git-send"} disabled={contactSubmitResponse.loading === true}>
              {contactSubmitResponse.loading === true ? 'Sending...' : 'Send'}
            </button>
          </div>

          {responseMessage && <p className={"response-message status-" + responseMessageStatus}>{responseMessage}</p>}

          <small>
            This site is protected by reCAPTCHA and the Google{' '}
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
          </small>
        </form>

        <ul className="social-links">
          <li>
            <button
              onClick={onMailTo}>
              <i className="icon-mail"></i><span className="visually-hidden">Send me an email</span>
            </button>
          </li>
          <li>
            <Link
              to="https://uk.linkedin.com/pub/taran-stafford/17/869/4b0"
              title="I'm on LinkedIn"
              target="_blank">
                <i className="icon-linkedin"></i>
              </Link>
          </li>
          <li>
            <Link
              to="skype:taran.stafford?chat"
              title="Message me on Skype"
            >
              <i className="icon-skype"  target="_blank"></i>
            </Link>
          </li>
        </ul>
      </div>
    </div>
    </>
  );
}
export default Contact;
