import { useApiGetJson, useApiPostFormJson } from './_client-hook';
import { config } from '../Config';

const baseUri = config.CF7.BaseUri;

// // Fetch form metadata
// const useFetchCF7FormMeta = (formId) => {
//   const url = formId ? `${baseUri}${formId}` : null;
//   return useApiGetJson(url);
// };

// Submit Contact Form 7 Data
const useSubmitContactForm = (send = false, formId = null, formData = null, timeout = 5000) => {
  // Construct the API endpoint for Contact Form 7 submission

  const url = send && formId ? `${baseUri}${formId}/feedback` : null;

  // Use the provided API hook for posting form data
  return useApiPostFormJson(url, formData, timeout);
};

export { useSubmitContactForm };
