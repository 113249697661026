import { useRef } from 'react';
import { Container, useTick } from '@pixi/react';

const MainTick = ({ children, func = null }) => {
  const elementRef = useRef(null);

	useTick((delta) => {
		if (elementRef.current) {
			if (func) {
				func(delta, elementRef.current);
			}
		}
	});

  return (
    <Container
			ref={elementRef}
    >
      {children}
    </Container>
  );
};
export default MainTick;
