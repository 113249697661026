
import ProjectTile from "./ProjectTile.js";

import Spinner from '../Spinner.js';

const ProjectList = ({projects = null, handleLoadMore = null, category='', isLoadingMore = false}) => {

	let projectList = null;
  let showLoadMore = false;

  let currTag = '';
  let currPage = 1;

  if (projects) {
    if (projects.tag) {
      projectList = projects.projects ?? [];
      showLoadMore = projects.total_posts > projectList.length;
			currTag = projects.tag;
			currPage = projects.page;
    }
    else {
      const result = projects.length ? projects.find(item => item.project_cat === category) : null;
			if (result) {
				projectList = result ? result.projects : [];
	      showLoadMore = result.total_posts > projectList.length;
				currPage = result.page;
			}
    }
  }

	if (!projectList) {
		return (
	    <div className="project-list">
				<Spinner />
	    </div>
	  );
	}
	else if (!projectList.length) {
		return (
	    <div className="project-list">
				<div className="project-list__msg">I'm still working on it. Check back soon!</div>
	    </div>
	  );
	}

  return (
    <div className="project-list">
			{
				projectList.map((project, i) => {
					return <ProjectTile key={i} project={project} />
				})
			}
			{ showLoadMore && <button className={isLoadingMore ? "load-more is-loading" : "load-more"} onClick={() => { handleLoadMore(category, currTag, currPage)} }>
				<span>Load More</span>
				<Spinner />
			</button>}
    </div>
  );
}
export default ProjectList;
