import React, { useEffect, useState, useRef } from 'react';
import { Container, Sprite, useTick } from '@pixi/react';

const Platforms = ({ anchor, positions, texture }) => {

  // State to track the platforms' positions, movements, and properties
  const [platforms, setPlatforms] = useState(positions);
  const containerRef = useRef(null);

  // Helper function to generate a random decimal between min and max
  const generateDecimalBetween = (min, max) => {
    return (Math.random() * (max - min) + min).toFixed(2);
  };

  // Randomize movement speeds for x and y directions
  const randomizeMovements = () => {
    return {
      movementX: parseFloat(generateDecimalBetween(0.1, 0.3)) * (Math.random() > 0.5 ? 1 : -1),
      movementY: parseFloat(generateDecimalBetween(0.05, 0.1)) * (Math.random() > 0.5 ? 1 : -1),
    };
  };

  // Initialize platform movements when component mounts
  useEffect(() => {

		const offX = -1000; // fix position
		const offY = -1500;

    const updatedPlatforms = platforms.map((platform) => ({
      ...platform,
			x:Math.floor(platform.x + offX),
			y:Math.floor(platform.y + offY),
			bounds:{
				x:Math.floor(platform.x + offX - 50),
				y:Math.floor(platform.y + offY - 20),
				w:100,
				h:40
			},
      ...randomizeMovements(), // Add randomized movement properties to each platform
    }));

    setPlatforms(updatedPlatforms);
  }, []);

  // Use `useTick` to animate the platforms' positions based on delta time
  useTick((delta) => {
    setPlatforms((prevPlatforms) =>
      prevPlatforms.map((platform) => {
        // Calculate new x and y positions based on movement speed and delta
        let newX = platform.x - platform.movementX * delta;
        let newY = platform.y - platform.movementY * delta;

        // Reverse movement if platform hits the x boundaries
        if (newX < platform.bounds.x) {
          newX = platform.bounds.x;
          platform.movementX *= -1;
        }
        if (newX > platform.bounds.x + platform.bounds.w) {
          newX = platform.bounds.x + platform.bounds.w;
          platform.movementX *= -1;
        }

        // Reverse movement if platform hits the y boundaries
        if (newY < platform.bounds.y) {
          newY = platform.bounds.y;
          platform.movementY *= -1;
        }
        if (newY > platform.bounds.y + platform.bounds.h) {
          newY = platform.bounds.y + platform.bounds.h;
          platform.movementY *= -1;
        }

        // Update the main platform and its twin position
        const updatedPlatform = {
          ...platform,
          x: newX,
          y: newY,
          twinX: platform.hasTwin ? newX + platform.twinOffX : null,
          twinY: platform.hasTwin ? newY + platform.twinOffY : null,
        };

        return updatedPlatform;
      })
    );
  });

  return (
    <Container
      ref={containerRef}
  		anchor={anchor}
		>
      {/* Render all platforms */}
      {platforms.map((platform, index) => {
				return (
	        <React.Fragment key={index}>
	          <Sprite
	            texture={texture}
	            x={platform.x}
	            y={platform.y}
	            scale={platform.scale}
	            anchor={[0.5, 0.5]}
	          />
	          {/* Render the twin if `hasTwin` is true
	          {platform.hasTwin && (
	            <Sprite
	              texture={texture}
	              x={platform.twinX}
	              y={platform.twinY}
	              scale={platform.scale}
	              anchor={[0.5, 0.5]}
	            />
	          )}*/}
	        </React.Fragment>
	      )
			})}
    </Container>
  );
};

export default Platforms;
