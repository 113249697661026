import {useApiGetJson} from './_client-hook';

import { config } from '../Config';
const baseUri = config.API.BaseUri;

// get projects
const useProjectsMethod = (send = true, term = null, tax = null) => {
  const query = `?term=${term}&taxonomy=${tax}`;
	const url = send ? `${baseUri}projects${query}` : null;
	return useApiGetJson(url);
}

// get project by project slug
const useProjectMethod = (send = true, slug = null) => {
	const url = send && slug ? `${baseUri}project/${slug}` : null;
	return useApiGetJson(url);
}

// get projects for category
const useMoreProjectsMethod = (send = true, term = null, tax = 'product_category', page = 1) => {
  let url;
  if (tax === 'project_category') {
    url = send && term ? `${baseUri}category/${term}/page/${page}` : null;
  }
	else {
    url = send && term ? `${baseUri}tag/${term}/page/${page}` : null;
  }
	return useApiGetJson(url);
}

// // get projects for category
// const useProjectsForCatMethod = (send = true, cat = null, page = 1) => {
// 	const url = send && cat ? `${baseUri}category/${cat}/page/${page}` : null;
// 	return useApiGetJson(url);
// }
//
// // get projects for tag
// const useProjectsForTagMethod = (send = true, tag = null, page = 1) => {
// 	const url = send && tag ? `${baseUri}tag/${tag}/page/${page}` : null;
// 	return useApiGetJson(url);
// }

export {
	useProjectsMethod,
	useProjectMethod,
  useMoreProjectsMethod
	// useProjectsForCatMethod,
	// useProjectsForTagMethod
};
