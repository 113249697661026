import { useState } from 'react';

import SubNav from "../components/home/SubNav";
import Section from "../components/Section";
import Header from "../layout/Header";
import Footer from "../layout/Footer";

const Error404 = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);

  // const clickAction = () => {
  //   console.log('clickAction');
  // }

  return (
    <>
			<a href="#main-content" className="skiptocontent visually-hidden focusable">Skip to main content</a>
			<Header />
			<div id="wrap" className="home home--404">

				<main role="main" id="main-content">

          <Section name="error404-menu">
            {/* Nav */}
            { !isMobile && <SubNav /> }
          </Section>

          <Section name="error404">
            <div className="error-404">
              <h1>404</h1>
    			    <p>It looks like nothing was found at this location.</p>
      			</div>
          </Section>

          <Footer />
				</main>
			</div>
    </>
  );
}

export default Error404;
